import { sanitizeUrl } from '@sephora-asia/sanitize-url'
import splitio from '@/modules/services/split-io'

const whitelistedHosts = ['pages.lazada.co.th']
function parameterize (string) {
  if (string) {
    // "&" is removed
    // "+" is removed
    // "/" is replaced with " "
    // all white space is replaced with "-"
    return string.replace(/&|\+/g, '').replace(/\//g, ' ').replace(/\s+/g, '-').toLowerCase()
  }
}

function stripHTMLTags (str) {
  // all tags will be replaced with an empty string ""
  // Ex: <b>Hello</b> <h1>world!</h1> => Hello world!
  return str && str.replace(/<\/?[^>]+(>|$)/g, '')
}

function underscore (str) {
  return str && str.toLowerCase().replace(/\s/g, '_')
}

const includedIn = (ids) => (obj) => ids.includes(obj.id)

const isTouchDevice = () => /Android|BlackBerry|iPhone|iPad|iPod|webOS/i.test(navigator.userAgent)

const fullUrl = path => window.config ? window.config.domain.replace(/\/$/, '') + path : window.location.origin + path

const monthOptions = [
  { value: '01', label: '01 - Jan' }, { value: '02', label: '02 - Feb' }, { value: '03', label: '03 - Mar' },
  { value: '04', label: '04 - Apr' }, { value: '05', label: '05 - May' }, { value: '06', label: '06 - Jun' },
  { value: '07', label: '07 - Jul' }, { value: '08', label: '08 - Aug' }, { value: '09', label: '09 - Sep' },
  { value: '10', label: '10 - Oct' }, { value: '11', label: '11 - Nov' }, { value: '12', label: '12 - Dec' }
]

const yearOptions = (numberOfYears) => {
  const currentYear = new Date().getFullYear()
  return Array.apply(0, Array(numberOfYears)).map((_, index) => (currentYear + index).toString())
}

function redirectToReturnPathIfPresent () {
  return new Promise(async (resolve) => {
    const path = await returnPath()

    if (!path) {
      resolve()
    } else if (isWhitelistedExternalHost()) {
      window.location.replace(path)
    } else {
      window.location.replace(fullUrl(path))
    }
  })
}

async function returnPath () {
  // for non-vue pages
  // should be replaced with router function in the future
  const searchParams = new URLSearchParams(window.location.search)
  const returnTo = searchParams.get('return_to') || searchParams.get('return-to') || searchParams.get('redirect_url')

  const isSanitizationEnabled = await isUrlSanitizationEnabled()

  if (isSanitizationEnabled && returnTo && !isWhitelistedExternalHost()) {
    return sanitizeUrl(returnTo, '/')
  } else {
    return returnTo
  }
}

function isWhitelistedExternalHost () {
  const searchParams = new URLSearchParams(window.location.search)
  const externalUrl = searchParams.get('redirect_url')
  if (!externalUrl) return false

  try {
    return whitelistedHosts.includes(new URL(externalUrl).host)
  } catch (e) {
    return false
  }
}

async function isUrlSanitizationEnabled () {
  let isSanitizationEnabled

  if (splitio.getTreatment('enable_url_sanitization') instanceof Promise) {
    isSanitizationEnabled = await splitio.getTreatment('enable_url_sanitization')
  } else {
    isSanitizationEnabled = splitio.getTreatment('enable_url_sanitization')
  }

  return isSanitizationEnabled
}

async function isEarlyAccessPlpFlagOn () {
  return new Promise((resolve) => {
    const treatment = splitio.getTreatment('enable_early_access_plp')
    resolve(treatment)
  })
}

function authenticationQueryOptions (query) {
  if (!query) return {}

  const {
    remindMeVariantId,
    tiers,
    prelaunchName,
    launchName,
    wishlist,
    waitlist
  } = query

  return {
    wishlistId: wishlist,
    waitlistId: waitlist,
    notificationSubscriptionEAPayload: {
      remindMeVariantId,
      tiers,
      prelaunchName,
      launchName
    }
  }
}

function clearLocalStorage (keysToRemove) {
  Object.keys(localStorage).forEach((key) => {
    if (keysToRemove.some((removalKey) => key.startsWith(removalKey))) {
      localStorage.removeItem(key)
    }
  })
}

export {
  parameterize,
  stripHTMLTags,
  underscore,
  includedIn,
  isTouchDevice,
  fullUrl,
  monthOptions,
  yearOptions,
  redirectToReturnPathIfPresent,
  returnPath,
  isUrlSanitizationEnabled,
  isEarlyAccessPlpFlagOn,
  authenticationQueryOptions,
  clearLocalStorage
}
